// Message.jsx
import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import remarkGfm from "remark-gfm"; // Importar remark-gfm para soporte de Markdown extendido
import "./Message.css";

function Message({ message }) {
  const isUser = message.role === "user";

  // Definir componentes personalizados para react-markdown
  const components = {
    a: ({ node, ...props }) => {
      const href = props.href || "";
      const imageExtensions = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
      const isImage = imageExtensions.some((ext) =>
        href.toLowerCase().endsWith(ext)
      );

      if (isImage) {
        return (
          <img
            src={href}
            alt="Imagen enviada por el asistente"
            style={{ maxWidth: "100%", marginTop: "10px" }}
          />
        );
      }

      return <a {...props}>{props.children}</a>;
    },
    // Puedes agregar más componentes personalizados si es necesario
  };

  return (
    <div className={`message ${isUser ? "user-message" : "bot-message"}`}>
      <ReactMarkdown
        children={message.content}
        components={components}
        remarkPlugins={[remarkGfm]} // Usar remark-gfm para soporte extendido
      />
    </div>
  );
}

Message.propTypes = {
  message: PropTypes.shape({
    role: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    timestamp: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
};

export default Message;
