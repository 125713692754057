import React, { useState, useEffect } from "react";
import ChatWindow from "./components/ChatWindow";
import { connectSocket, disconnectSocket, getSocket } from "./socket";
import "./ChatWindow.css";

function App() {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken")
  );
  const [messages, setMessages] = useState([]);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false); // Modo Claro por defecto
  const [errorMessage, setErrorMessage] = useState(null); // Nuevo estado para mensajes de error

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Definir la Función fetchSessionToken Fuera del useEffect
  const fetchSessionToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/get_session_token`
      );
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      localStorage.setItem("accessToken", data.access_token);
      localStorage.setItem("refreshToken", data.refresh_token);
    } catch (error) {
      console.error("Error al obtener nuevos tokens de sesión:", error);
      // Limpiar tokens si hay un error
      setAccessToken(null);
      setRefreshToken(null);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      setErrorMessage(
        "Error al obtener la sesión. Por favor, recarga la página."
      ); // Establecer mensaje de error
    }
  };

  const refreshAccessToken = async () => {
    if (isRefreshingToken) return;
    setIsRefreshingToken(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/refresh_token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh_token: refreshToken }),
        }
      );
      if (!response.ok) {
        if (response.status === 401) {
          // El refresh token ha expirado o es inválido
          console.warn(
            "Refresh token expirado o inválido. Generando nuevos tokens..."
          );
          await fetchSessionToken(); // Solicitar nuevos tokens automáticamente
          return;
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setAccessToken(data.access_token);
      localStorage.setItem("accessToken", data.access_token);
    } catch (error) {
      console.error("Error al refrescar el access token:", error);
      console.warn("Intentando obtener nuevos tokens automáticamente...");
      await fetchSessionToken();
      setErrorMessage(
        "Error al refrescar la sesión. Por favor, recarga la página."
      ); // Establecer mensaje de error
    } finally {
      setIsRefreshingToken(false);
    }
  };

  // Modificar el useEffect Inicial para Usar fetchSessionToken
  useEffect(() => {
    const initializeSession = async () => {
      if (!accessToken) {
        await fetchSessionToken();
      }
    };

    initializeSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Eliminar accessToken de las dependencias para evitar loops

  useEffect(() => {
    if (!accessToken) return;

    connectSocket(accessToken);
    const socket = getSocket();

    const handleConnect = () => {
      console.log("Socket conectado");
      setIsSocketConnected(true);
    };

    const handleDisconnect = (reason) => {
      console.log("Socket desconectado:", reason);
      setIsSocketConnected(false);
    };

    const handleConnectError = async (error) => {
      console.error("Error al conectar el socket:", error);
      setIsSocketConnected(false);
      if (
        error &&
        error.data &&
        error.data.message === "Access token inválido o expirado."
      ) {
        console.warn("Access token expirado. Intentando renovar...");
        await refreshAccessToken();
      }
    };

    const handleError = async (error) => {
      if (
        error.message === "Access token requerido." ||
        error.message === "Access token inválido o expirado."
      ) {
        console.warn(
          "Token inválido o expirado. Intentando refrescar token..."
        );
        await refreshAccessToken();
        setErrorMessage(error.message); // Establecer mensaje de error
      }
    };

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("connect_error", handleConnectError);
    socket.on("error", handleError);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("connect_error", handleConnectError);
      socket.off("error", handleError);
      disconnectSocket();
    };
  }, [accessToken]);

  const resetSession = async () => {
    // Borrar localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    // Actualizar el estado de los tokens
    setAccessToken(null);
    setRefreshToken(null);

    // Desconectar el socket actual
    disconnectSocket();

    // Obtener nuevos tokens de sesión
    await fetchSessionToken();
  };

  // Añadir useEffect para reconectar el socket cuando se actualizan los tokens
  useEffect(() => {
    if (accessToken && isSocketConnected) {
      disconnectSocket();
      connectSocket(accessToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, refreshToken]);

  // Función para cerrar el mensaje de error
  const closeError = () => {
    setErrorMessage(null);
  };

  if (isRefreshingToken) {
    return <div>Cargando nueva sesión...</div>;
  }

  if (!accessToken) {
    return <div>Cargando sesión...</div>;
  }

  if (!isSocketConnected) {
    return (
      <div className="connection-error">
        <p>Connection lost. Please reload the page.</p>
        <button
          className="reload-button"
          onClick={resetSession} // Mantén esta línea para que funcione la recarga
        >
          Reload
        </button>
      </div>
    );
  }

  return (
    <div className={`app ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      {/* Mensaje de Error */}
      {errorMessage && (
        <div className="error-notification">
          <p>{errorMessage}</p>
          <button className="close-btn" onClick={closeError}>
            &times;
          </button>
        </div>
      )}
      <ChatWindow
        accessToken={accessToken}
        refreshToken={refreshToken}
        messages={messages}
        addMessage={addMessage}
        setMessages={setMessages}
        isDarkMode={isDarkMode}
        toggleTheme={toggleTheme}
        // resetSession={resetSession} // Eliminar esta línea si no se usa en ChatWindow
      />
    </div>
  );
}

export default App;
