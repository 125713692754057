import io from "socket.io-client";

let socket = null;

/**
 * Conecta al servidor de Socket.IO usando un token de sesión único.
 * @param {string} accessToken - El token de sesión único generado por el backend.
 */
export const connectSocket = (accessToken) => {
  const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || window.location.origin;

  // Desconectar cualquier socket existente antes de crear uno nuevo
  if (socket) {
    socket.disconnect();
  }

  socket = io(SOCKET_URL, {
    query: { access_token: accessToken }, // Asegúrate de que el backend espera 'access_token'
    transports: ["websocket"],
    secure: true, // Asegura conexiones seguras en producción
    reconnection: true, // Habilita la reconexión automática
    reconnectionAttempts: 5, // Número de intentos de reconexión
    reconnectionDelay: 1000, // Retardo inicial en ms
    reconnectionDelayMax: 5000, // Retardo máximo en ms
  });

  socket.on("connect", () => {
    console.log("Conectado al servidor de Socket.IO");
  });

  socket.on("disconnect", () => {
    console.log("Desconectado del servidor de Socket.IO");
  });

  socket.on("connect_error", (err) => {
    console.error("Error en la conexión de Socket.IO:", err);
  });

  // Opcional: Manejar otros eventos personalizados si es necesario
};

/**
 * Desconecta del servidor de Socket.IO.
 */
export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

/**
 * Obtiene la instancia actual del socket.
 * @returns {Socket} La instancia del socket actual.
 */
export const getSocket = () => socket;
