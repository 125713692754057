import React, { useState, useEffect, useRef } from "react";
import Message from "./Message";
import { getSocket, disconnectSocket } from "../socket";

function ChatWindow({
  accessToken,
  refreshToken,
  messages,
  addMessage,
  setMessages,
  isDarkMode, // Nueva prop para el modo oscuro
  toggleTheme, // Nueva prop para alternar el modo
}) {
  const [input, setInput] = useState("");
  const [currentMessage, setCurrentMessage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showThreadPopup, setShowThreadPopup] = useState(false);
  const messagesEndRef = useRef(null);
  const [loading, setLoading] = useState(false); // Nuevo estado para el mensaje de carga
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }
  
    const handleResponseComplete = () => {
      setLoading(false);
    };
  
    socket.on("response_complete", handleResponseComplete);
  
    return () => {
      socket.off("response_complete", handleResponseComplete);
    };
  }, []);

  useEffect(() => {
    // Obtener la fecha actual en formato DD/MM/YY
    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(
      date.getMonth() + 1
    ).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;
    setCurrentDate(formattedDate);
  }, []);

  useEffect(() => {
    const socket = getSocket(); // Obtener el socket
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }
  
    const handleReceiveMessage = (message) => {
      console.log("[handleReceiveMessage] Mensaje recibido:", message); // Logging
    
      // **Añadir sanitización del contenido del mensaje**
      const sanitizedContent = message.content.replace(/【\d+:\d+†source】/g, '').trim();
    
      setCurrentMessage({ role: "bot", content: sanitizedContent, timestamp: new Date() });
    
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;
        const lastMessage = updatedMessages[lastMessageIndex];
    
        if (lastMessage && lastMessage.role === "bot") {
          // Actualiza el contenido del último mensaje del bot
          updatedMessages[lastMessageIndex] = {
            ...lastMessage,
            content: sanitizedContent,
            timestamp: new Date(),
          };
        } else {
          // Agrega un nuevo mensaje del bot
          updatedMessages.push({ role: "bot", content: sanitizedContent, timestamp: new Date() });
        }
    
        return updatedMessages;
      });
    };
  
    socket.on("receive_message", handleReceiveMessage);
  
    return () => {
      socket.off("receive_message", handleReceiveMessage);
    };
  }, [addMessage, loading]); // Eliminado 'currentMessage'

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }
  }, [/* onLogout */]); // **Eliminar onLogout de las dependencias**

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }

    const handleThreadCreated = () => {
      setShowThreadPopup(true);
      setTimeout(() => {
        setShowThreadPopup(false);
      }, 2000);
    };

    socket.on("thread_created", handleThreadCreated);

    return () => {
      socket.off("thread_created", handleThreadCreated);
    };
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = async () => {
    if (loading) {
      // Evita enviar mensajes si hay una respuesta activa
      return;
    }
  
    const socket = getSocket();
    console.log("[sendMessage] Enviando mensaje:", input);
  
    if (!socket || !socket.connected) {
      console.warn("Socket no está conectado.");
      return;
    }
  
    if (input.trim()) {
      // Enviar solo el contenido del input
      socket.emit("send_message", {
        content: input,
      });
  
      // Añadir mensaje al chat con timestamp
      addMessage({ role: "user", content: input, timestamp: new Date() });
      console.log("[sendMessage] Mensaje añadido al chat.");
  
      setCurrentMessage(null);
      setLoading(true);
      setInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (loading) {
        // Evita enviar mensajes si hay una respuesta activa
        e.preventDefault(); // Opcional: Puedes mostrar una notificación al usuario
        return;
      }
      e.preventDefault(); // Evita la inserción de una nueva línea
      sendMessage(); // Llama a la función sendMessage para enviar el mensaje
    }
  };

  const reloadThread = () => {
    const socket = getSocket();

    // Borra todos los mensajes de la interfaz
    setMessages([]); // Limpia los mensajes cuando se recarga el thread

    // Envía la señal para crear un nuevo thread sin contenido
    socket.emit("send_message", {
      new_thread: true, // Indica que se debe crear un nuevo thread
      // thread_id ya no es necesario y se elimina
    });
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <div className={`app ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      <header className="chat-header">
        {/* Botón de Recarga */}
        <button className="reload-btn" onClick={reloadThread}>
          <img src="https://util-ia.site/img/reload.svg" alt="Recargar" />
        </button>
  
        {/* Contenedor central del header */}
        <div className="header-center">
          <a href="https://www.impressiveresortsloszocos.com" target="_blank" rel="noopener noreferrer">
            <img
              src="https://util-ia.site/img/impressiveblanco.svg"
              alt="Logo Impressive"
              className="main-logo"
            />
          </a>
  
          {/* Texto debajo del logo */}
          <div className="header-text">
            Chatbot Los Zocos Impressive Lanzarote
          </div>
        </div>
  
        {/* Botón de Cambio de Tema */}
        <button className="theme-toggle-btn" onClick={toggleTheme}>
          <img
            src={isDarkMode ? "https://util-ia.site/img/sun.svg" : "https://util-ia.site/img/moon.svg"}
            alt={isDarkMode ? "Modo Oscuro" : "Modo Brillante"}
          />
        </button>
      </header>
      {/* Fecha actual */}
      <div className="current-date">
        {currentDate}
      </div>

      {/* Ventana del Chat (Solo Mensajes) */}
      <div className="chat-window">
        <div className="messages">
          {showThreadPopup && (
            <div className="popup">
              <div className="popup-content">
                <p>Conversation reloaded successfully!</p>
              </div>
            </div>
          )}
          {messages.map((msg, idx) => (
            <div
              key={idx}
              className={`message-container ${
                msg.role === "user"
                  ? "user-message-container"
                  : "bot-message-container"
              }`}
            >
              <div className="message-wrapper">
                {/* Mostrar el mensaje */}
                <Message message={msg} />
                {/* Mostrar la hora del mensaje */}
                <span className="message-timestamp">
                  {formatTimestamp(msg.timestamp)}
                </span>
              </div>
            </div>
          ))}
          {loading && (
            <div className="message-container bot-message-container">
              <div className="message-wrapper">
                <p className="loading-message">Loading</p>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Área de Entrada de Mensaje */}
      <div className="input-area">
        <textarea
          rows="1"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Your message..."
        />
        <button
          className={`send-btn ${loading ? 'disabled' : ''}`}
          onClick={sendMessage}
          disabled={loading}
          >
          <img src="https://util-ia.site/img/send.svg" alt="Enviar" />
        </button>
      </div>

      {/* Aviso debajo del input */}
      <div className="notice">
        <p>
          This Chatbot uses AI and can make mistakes
        </p>
      </div>

      {/* Footer */}
      <div className="power-utilia">
        <p>Powered by</p>
        <a href="https://utilia.ai" target="_blank" rel="noopener noreferrer">
          <img src="https://util-ia.site/img/logoutilia.svg" alt="Logo Utilia" className="logoutilia" />
        </a>
      </div>
    </div>
  );
}

export default ChatWindow;
